/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import Layout from '../components/layout'
import Wrapper from '../components/layout/wrapper'
import Inner from '../components/layout/inner'
import { HeroLayout, HeroTitle } from '../components/hero'
import { Stack } from 'raam'
import { Link } from 'gatsby'
import MetaData from '../components/metadata'

const NotFound = (props) => {

  return (
    <Layout {...props}>
      <MetaData title="Not Found" />
      <HeroLayout>
        <HeroTitle>Page Not Found</HeroTitle>
      </HeroLayout>
      <Wrapper>
        <Inner>
          <Stack sx={{
            py: 6,
            textAlign: 'center'
          }}>
            <Text>The page you're looking for has been removed or relocated.</Text>
            <Link to="/" sx={{
              color: 'black',
              textDecoration: 'none',
              borderBottom: '1px solid',
              borderColor: 'primary'
            }}>Go back</Link>
          </Stack>
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export default NotFound